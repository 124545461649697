.thumbnail{
    width: 240px;
    height: 240px;
    margin:  0 auto;
}

.ap-col {
    flex-grow: 0;
    min-width: 260px;
    padding: 9px;
    text-align: center;
}

.btn {
    padding: 0;
    background-color: white;
    margin: 0;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.btn:focus {
    outline:0;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.ap-container {
    position: relative;
}

.ap-proj-desc {
    position: absolute;
    left: 0px;
    width: 100%;
    background-color: #f3f1ec;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 30px 20px;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.icon {
    width: 50px;
    padding-right: 20px;
}

@media (max-width: 992px) {

.ap-col {
    flex-grow: 1;
}

}