.navbar {
  background-color: white;
}

.navbar-brand {
    font-size: 32px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.nav-link  {
    font-weight: 200;
    font-size: 13px;
    letter-spacing: 1px;
    color: black;
}

.navbar-light .navbar-toggler {
    color: white;
    background-color: black;
    font-size: 11px;
    height: 30px;
    width: 70px;
    border-radius: 0;
    border-color: grey;
    margin: 20px;
}

a {
    color: black;
    text-decoration: none;
}

a:hover{
    color: black;
    text-decoration: none;
}

.black-underline {
    border-bottom: 2px solid black;
}

.hvr-underline-reveal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden;
  }
  
  .hvr-underline-reveal:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: #8e8e8e;
    height: 2px;
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }