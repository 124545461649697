@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i');
@import url('https://fonts.googleapis.com/css?family=Heebo:900');

h1{
  font-family: "Heebo", sans-serif;
  font-size: 50px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h2{ /*SUBTITLE*/
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0;
}

h3{ /* BODY TITLES*/
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
}

h4{ /*MENU ITEM*/
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 11.5px;
  letter-spacing: 1px;
  padding: .5em;
  text-align: center;
  
}

h5{
  font-family: "Open Sans", sans-serif;
  letter-spacing: 2px;
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 0em;
}

p{
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: justify;
  white-space: pre-line;
}

p a{
  color:#165c84;
}

li {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: justify;
  white-space: pre-line;
}

body {
  font-family: "Open Sans", sans-serif;
}

.container {
  margin-bottom: 50px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey; 
  border-radius: 1px;
}

#loris {
  position:fixed;
  width:250px;
  height:250px;
  right:0;
  bottom:0;
}

@media (max-width: 992px) {

  #loris {
    width: 150px;
    height: 150px;
  }
  
  }