@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i);
@import url(https://fonts.googleapis.com/css?family=Heebo:900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: "Heebo", sans-serif;
  font-size: 50px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h2{ /*SUBTITLE*/
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 0;
}

h3{ /* BODY TITLES*/
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
}

h4{ /*MENU ITEM*/
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 11.5px;
  letter-spacing: 1px;
  padding: .5em;
  text-align: center;
  
}

h5{
  font-family: "Open Sans", sans-serif;
  letter-spacing: 2px;
  font-size: 28px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 0em;
}

p{
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: justify;
  white-space: pre-line;
}

p a{
  color:#165c84;
}

li {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: justify;
  white-space: pre-line;
}

body {
  font-family: "Open Sans", sans-serif;
}

.container {
  margin-bottom: 50px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey; 
  border-radius: 1px;
}

#loris {
  position:fixed;
  width:250px;
  height:250px;
  right:0;
  bottom:0;
}

@media (max-width: 992px) {

  #loris {
    width: 150px;
    height: 150px;
  }
  
  }
.thumbnail{
    width: 240px;
    height: 240px;
    margin:  0 auto;
}

.ap-col {
    flex-grow: 0;
    min-width: 260px;
    padding: 9px;
    text-align: center;
}

.btn {
    padding: 0;
    background-color: white;
    margin: 0;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.btn:focus {
    outline:0;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.ap-container {
    position: relative;
}

.ap-proj-desc {
    position: absolute;
    left: 0px;
    width: 100%;
    background-color: #f3f1ec;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 30px 20px;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  transform: scale(1.01);
}

.icon {
    width: 50px;
    padding-right: 20px;
}

@media (max-width: 992px) {

.ap-col {
    flex-grow: 1;
}

}
.cp-col {
    min-width: unset;
    padding: 20px;
}
.navbar {
  background-color: white;
}

.navbar-brand {
    font-size: 32px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.nav-link  {
    font-weight: 200;
    font-size: 13px;
    letter-spacing: 1px;
    color: black;
}

.navbar-light .navbar-toggler {
    color: white;
    background-color: black;
    font-size: 11px;
    height: 30px;
    width: 70px;
    border-radius: 0;
    border-color: grey;
    margin: 20px;
}

a {
    color: black;
    text-decoration: none;
}

a:hover{
    color: black;
    text-decoration: none;
}

.black-underline {
    border-bottom: 2px solid black;
}

.hvr-underline-reveal {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden;
  }
  
  .hvr-underline-reveal:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    background: #8e8e8e;
    height: 2px;
    transform: translateY(4px);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  .hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
    transform: translateY(0);
  }
  .hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  }
  .hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
    transform: scale(1.05);
  }
